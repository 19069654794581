import React from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import { CssBaseline } from '@material-ui/core'

import { GlobalStyleProps } from '../types'
// app styles
import '../global.css'

const GlobalStyle = createGlobalStyle<GlobalStyleProps>`
  html,
  body,
  #root {
    height: 100%;
  }

  body {
    background: ${props => props.theme.body.background};
  }
`

const Root = styled.div``

const Public: React.FC = ({ children }: any) => {
  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      {children}
    </Root>
  )
}

export default Public
