import { Button, Grid, IconButton, Typography } from '@material-ui/core'
import { CloseOutlined } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import React, { useState } from 'react'
import referalLinkIcon from '../../assets/icons/referalLink.svg'
import useGetBrandSettings from '../../hooks/useGetBrandSettings'
import { useHistory } from 'react-router-dom'
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core'
import financeIcon from '../../assets/icons/finance-black.svg'

type Props = {}

const useStyles = makeStyles(() => ({
  box: {
    padding: '24px 40px',
    background: '#D6F0ED',
    position: 'relative',
  },
  closeButton: {
    position: 'absolute',
    top: 5,
    right: 5,
  },
}))

const ReferalLinksBox: React.FC<Props> = () => {
  const classes = useStyles()
  const { loading, data } = useGetBrandSettings()
  const [showReferralBox, setShowReferralBox] = useState(true)
  const [open, setOpen] = useState(false)
  const history = useHistory()
  if (loading) return null

  const handleClose = () => {
    setOpen(false)
  }

  const handleOpen = () => {
    setOpen(true)
  }

  const haveCommissionBalance = data?.getBrandSettings?.commissionsBalance
  const reviewBrands =
    process.env.REACT_APP_REVIEW_BRANDS?.toLocaleLowerCase().split(',') || []
  const email = data?.getBrandSettings.email
  return (
    <>
      {!haveCommissionBalance &&
      data?.getBrandSettings?.isShopSetup &&
      data?.getBrandSettings?.isShopSetup &&
      !reviewBrands.includes(email) &&
      showReferralBox ? (
        <div className={classes.box}>
          <Grid container wrap="nowrap">
            <Grid
              item
              container
              wrap="nowrap"
              spacing={3}
              style={{ flexGrow: 1 }}
            >
              <Grid item>
                <img src={referalLinkIcon} alt="referal" />
              </Grid>
              <Grid item>
                <Typography variant="h6" className="mb-2">
                  Increase sales with 25% - Enable referral links
                </Typography>
                <Typography variant="body1" className={'mr-3'}>
                  There are two ways for customers to purchase on a MyShop
                  storefront{' '}
                  <span
                    className="read-more-link cursor-pointer"
                    onClick={handleOpen}
                  >
                    (Read more)
                  </span>
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              container
              alignItems="center"
              justify="flex-end"
              style={{ width: 'auto' }}
            >
              <Button
                variant="contained"
                className="pl-3"
                style={{ background: '#10C095', color: 'white', width: 240 }}
                onClick={() => history.push('/withdraw?query=commission')}
              >
                Update Commission Account
              </Button>
            </Grid>
          </Grid>
          <IconButton
            onClick={() => setShowReferralBox(false)}
            className={classes.closeButton}
            aria-label="close"
          >
            <CloseOutlined fontSize="small" />
          </IconButton>
        </div>
      ) : null}

      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={'md'}
        PaperProps={{
          style: {
            padding: '20px 10px 20px 10px',
          },
        }}
      >
        <DialogTitle>
          <img className="mr-2 text-dark" src={financeIcon} alt="finance" />
          {'How do ordering and referral links work?'}
        </DialogTitle>
        <DialogContent>
          <div className="pl-5">
            <Typography variant="body1" className="mb-3">
              There are two ways for a customer to buy something through a
              MyShop Storefront
            </Typography>
            <Typography variant="body2" className="font-weight-500 mb-1">
              1. Customer completes the order and payment on the MyShop
              Storefront
            </Typography>
            <Typography variant="body1" className="mb-3">
              The customer visits a MyShop Storefront and completes the order
              and payment on the MyShop side.
            </Typography>
            <Typography variant="body1" className="mb-3">
              The payment, minus any commission owed, will be transferred to
              your Stripe or MyShop Earnings Account.
            </Typography>

            <Typography variant="body2" className="font-weight-500 mb-1">
              2. Customer clicks a referral link on a MyShop storefront and
              completes the order and payment on your webshop.
            </Typography>
            <Typography variant="body1" className="mb-3">
              The customer clicks on the URL to your webshop or on your logo in
              a myshop storefront and is sent to your webshop where they
              complete the purchase and payment.
            </Typography>
            <Typography variant="body1" className="mb-3">
              To enable these referral links, the commission account needs to be
              topped up. Any commission owed for the respective referral link
              orders will be deducted from this account.
            </Typography>
            <Button
              color="default"
              variant="contained"
              className="mb-4 px-4 mt-4"
              onClick={handleClose}
            >
              Close
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default ReferalLinksBox
