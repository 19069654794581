import { useQueryExt } from '../hooks/useQueryWrapper'
import GetStatistics from './graphql/GetStatistics.graphql'

export function useGetStatistics() {
  const { data, loading, error } = useQueryExt(
    GetStatistics,
    {
      fetchPolicy: 'cache-and-network',
    },
    'getStatistics',
  )
  return { data, loading, error }
}

export default useGetStatistics
