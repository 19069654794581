import { gql } from '@apollo/client'

const GetBrandSettings = gql`
  query getBrandSettings {
    getBrandSettings {
      name
      email
      status
      myShopReferences
      commissionsBalance
      logo
      settings {
        pageImage
      }
      stripe {
        id
        lastCardDigits
        expireMonth
        expireYear
        cardType
        postalCode
        name
      }
      accountBalance {
        commissionsBalance
      }
      isShopSetup
      chargeSettings {
        minimumGuarantee
        billingInformation {
          name
          companyNumber
          address1
          address2
          countryCode
          city
          state
          postalCode
          email
          phone
          primaryPerson
        }
      }
    }
  }
`

export default GetBrandSettings
