export const TOKEN_KEY = 'myshop-token'

export const persistToken = (token: string) => {
  localStorage.setItem(TOKEN_KEY, token)
}

export const getToken = (): string => {
  return localStorage.getItem(TOKEN_KEY) || ''
}

export const signOut = (): void => {
  return localStorage.removeItem(TOKEN_KEY)
}
