import { useQueryExt } from '../hooks/useQueryWrapper'
import GetBrandSettings from './graphql/GetBrandSettings.graphql'

type callbackType = (data: any) => void

export function useGetBrandSettings(callback?: callbackType) {
  const query = GetBrandSettings
  const { data, loading, error } = useQueryExt(
    query,
    {
      fetchPolicy: 'cache-and-network',
      onCompleted: (data: any) => {
        callback && callback(data?.getBrandSettings)
      },
    },
    'getBrandSettings',
  )
  return { data, loading, error }
}

export default useGetBrandSettings
