import React from 'react'
import { Route } from 'react-router-dom'
import { RouteProps } from 'react-router'
import { useHistory } from 'react-router-dom'
import { isLogin } from '../utils/auth'

export const PrivateRoute: React.FC<RouteProps> = (props: RouteProps) => {
  const loggedIn = isLogin()
  const { location = { pathname: '', search: '' } } = props

  const redirectPath = `?redirect=${location.pathname}${location.search}`
  const history = useHistory()

  if (!loggedIn) {
    history.push('/login' + redirectPath)
  }

  return <Route {...props} />
}
