import { ChildElementType } from '../types'
import { client } from '../App'
import GetBrandSettings from '../hooks/graphql/GetBrandSettings.graphql'
import React from 'react'
import useGetShopSettings from '../hooks/useGetShopSettings'
import { LinkProps, NavLink as RouterNavLink } from 'react-router-dom'
import Loader from '../components/SkeletonLoader'

import { Link, LinkText, LinkBadge } from '../components/Dashboard/Sidebar'

const getBrandSettings = async () => {
  if (client) {
    return await client.query({
      query: GetBrandSettings,
      fetchPolicy: 'cache-first',
    })
  }
}

type SidebarLinkPropsType = {
  name: string
  to: any
  badge?: string | number
  icon?: JSX.Element
  settingsId: string
}

const NavLink = React.forwardRef<LinkProps, any>((props, ref) => (
  <RouterNavLink innerRef={ref} {...props} />
))
const SidebarSettingLink: React.FC<any> = ({
  to,
  badge,
  settingsId,
}: SidebarLinkPropsType) => {
  const { data, loading } = useGetShopSettings(() => {}, settingsId)
  if (loading)
    return (
      <div style={{ textAlign: 'center' }}>
        <Loader.SideBarChildLoader></Loader.SideBarChildLoader>
      </div>
    )
  return (
    <Link
      button
      dense
      component={NavLink}
      exact
      to={to}
      activeclassname="active"
    >
      <LinkText style={{ marginLeft: '10px' }}>
        {data?.getShopSettings?.displayName || 'Complete your store settings'}
      </LinkText>
      {badge ? <LinkBadge label={badge} /> : ''}
    </Link>
  )
}

const SideBarSimpleLink: React.FC<any> = ({
  to,
  badge,
}: SidebarLinkPropsType) => {
  return (
    <Link
      button
      dense
      component={NavLink}
      exact
      to={to}
      activeclassname="active"
    >
      <LinkText style={{ marginLeft: '10px' }}>General Settings</LinkText>
      {badge ? <LinkBadge label={badge} /> : ''}
    </Link>
  )
}
export const getShopSettings = async () => {
  const { data }: any = await getBrandSettings()
  const shops = data?.getBrandSettings?.myShopReferences || []

  const routes: Array<ChildElementType> = new Array<ChildElementType>()
  routes.push({
    path: '/settings',
    name: 'General Settings',
    component: SideBarSimpleLink,
    key: '',
  })
  shops.forEach((shop: string) => {
    routes.push({
      path: `/settings/${shop}`,
      name: '',
      component: SidebarSettingLink,
      key: shop,
    })
    return
  })
  return routes
}
