import React from 'react'
import ReactGA, { EventArgs } from 'react-ga'
import { Route } from 'react-router-dom'

interface GoogleAnalyticsProps {
  location: {
    pathname?: string
    search?: string
    hash?: string
  }
  options?: object
}

class GoogleAnalytics extends React.Component<GoogleAnalyticsProps> {
  componentDidMount() {
    const path = this.props?.location?.pathname
    const search = this.props?.location?.search
    const hash = this.props?.location?.hash
    this.logPageChange(path, search, hash)
  }

  componentDidUpdate({ location }: { location: any }) {
    const {
      location: { pathname, search, hash },
    } = this.props
    const isDifferentPathname = pathname !== location.pathname
    const isDifferentSearch = search !== location.search
    const isDifferentHash = hash !== location.hash

    if (isDifferentPathname || isDifferentSearch || isDifferentHash) {
      this.logPageChange(pathname, search, hash)
    }
  }

  logPageChange(pathname: any, search = '', hash = '') {
    const page = pathname + search
    const { location } = window
    ReactGA.set({
      page,
      location: `${location.origin}${page}`,
      hash,
      ...this.props.options,
    })
    ReactGA.pageview(page)
  }

  render() {
    return null
  }
}

const RouteTracker = () => <Route component={GoogleAnalytics} />

const init = (options = {}) => {
  const isGAEnabled = process.env.NODE_ENV === 'production'
  const GA_ID = process.env.GA_ADMIN_ID || 'UA-93666042-1'
  if (isGAEnabled) {
    ReactGA.initialize(GA_ID, options)
  }

  return isGAEnabled
}

const track = (args: EventArgs) => {
  const isGAEnabled = process.env.NODE_ENV === 'production'
  if (isGAEnabled) {
    ReactGA.event(args)
  }
}

export default {
  GoogleAnalytics,
  RouteTracker,
  init,
  track,
}
