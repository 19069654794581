import React from 'react'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  RouteComponentProps,
} from 'react-router-dom'
import {
  dashboardLayoutRoutes,
  publicLayoutRoutes,
  integrationAuthRoutes,
} from './index'

import DashboardLayout from '../layouts/Dashboard'
import PublicLayout from '../layouts/Public'
import Page404 from '../screens/errors/Page404'
import { RouteInfoType } from '../types'
import ScrollToTop from './ScrollToTop'
import { PrivateRoute } from './PrivateRoute'

const childRoutes = (Layout: React.ElementType, routes: Array<RouteInfoType>) =>
  routes.map(
    ({ component: Component, children, path, isPrivate }, index: number) => {
      const SelectedRouter = isPrivate ? PrivateRoute : Route
      return children ? (
        children.map((element, index: number) => (
          <SelectedRouter
            key={index}
            path={element.path}
            exact
            render={(props: RouteComponentProps) => (
              <Layout>
                <element.component {...props} />
              </Layout>
            )}
          />
        ))
      ) : Component ? (
        <SelectedRouter
          key={index}
          path={path}
          exact
          render={props => (
            <Layout>
              <Component {...props} />
            </Layout>
          )}
        />
      ) : null
    },
  )

const Routes = () => (
  <Router>
    <ScrollToTop />
    <Switch>
      {childRoutes(DashboardLayout, dashboardLayoutRoutes)}
      {childRoutes(PublicLayout, publicLayoutRoutes)}
      {childRoutes(PublicLayout, integrationAuthRoutes)}
      <Route
        render={() => (
          <PublicLayout>
            <Page404 />
          </PublicLayout>
        )}
      />
    </Switch>
  </Router>
)

export default Routes
