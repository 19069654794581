import { gql } from '@apollo/client'

const GetStatistics = gql`
  query getStatistics {
    getStatistics {
      revenue
      activeShops
      impressions
      publishedProducts
      unpublishedProducts
    }
  }
`

export default GetStatistics
