import React from 'react'
import styled, { withTheme } from 'styled-components'
import {
  Grid,
  Hidden,
  AppBar as MuiAppBar,
  IconButton as MuiIconButton,
  Toolbar,
} from '@material-ui/core'
import { Menu as MenuIcon } from '@material-ui/icons'

const AppBar = styled(MuiAppBar)`
  background: ${props => props.theme.header.background};
  color: ${props => props.theme.header.color};
  box-shadow: ${props => props.theme.shadows[1]};
`

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`

type HeaderProps = {
  theme: {}
  onDrawerToggle: React.MouseEventHandler<HTMLElement>
}

const Index: React.FC<HeaderProps> = ({ onDrawerToggle }: HeaderProps) => (
  <React.Fragment>
    <Hidden mdUp>
      <AppBar position="sticky" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center">
            <Grid item>
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={onDrawerToggle}
              >
                <MenuIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </Hidden>
  </React.Fragment>
)

export default withTheme(Index)
