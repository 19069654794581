import React from 'react'
import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'

import { ApolloProvider } from '@apollo/react-hooks'
import ReactNotification from 'react-notifications-component'

import Helmet from 'react-helmet'
import { ErrorBoundary } from 'react-error-boundary'
import { Router } from 'react-router-dom'

import { AppProvider, Frame, Loading } from '@shopify/polaris'
import translations from '@shopify/polaris/locales/en.json'

import history from './history'

import StylesProvider from './StylesProvider'

import { TOKEN_KEY } from './hooks/utils'
import 'react-notifications-component/dist/theme.css'

import GA from './lib/GoogleAnalytics'

import Root from './routes/Routes'
import { UpdateModalProvider } from './components/UpdatesModal/context'

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_APOLLO_SERVER,
})

const authLink = setContext((_, { headers }) => {
  const urlParams = new URLSearchParams(window.location.search)
  const jwtURLToken = urlParams.get('jwt')
  if (jwtURLToken) {
    localStorage.setItem(TOKEN_KEY, jwtURLToken)
  }
  const token = localStorage.getItem(TOKEN_KEY)
  return {
    headers: {
      ...headers,
      authorization: token || '',
    },
  }
})

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
})
const App = () => {
  return (
    <ApolloProvider client={client}>
      <AppProvider i18n={translations}>
        <StylesProvider>
          <UpdateModalProvider>
            <Router history={history}>
              {GA.init() && <GA.RouteTracker />}
              <Helmet titleTemplate="%s - MyShop" defaultTitle="MyShop" />
              <ErrorBoundary FallbackComponent={() => <div>Error</div>}>
                <React.Suspense
                  fallback={
                    <Frame>
                      <Loading />
                    </Frame>
                  }
                >
                  <ReactNotification />
                  <Root />
                </React.Suspense>
              </ErrorBoundary>
            </Router>
          </UpdateModalProvider>
        </StylesProvider>
      </AppProvider>
    </ApolloProvider>
  )
}

export default App
