import { gql } from '@apollo/client'

const GetShopSettings = gql`
  query getShopSettings($input: shopSettingsInput) {
    getShopSettings(input: $input) {
      displayName
      allowAmbassadors
      allowCustomDescription
      allowImageUpload
      allowVideoUpload
      deliveryTime
      disabled
      discountPercentage
      shippingCountries
      shopType
      supportEmail
      webshopURL

      brandId
      stripeConnectId
      webshopAccessToken
      privacyPolicyUrl
      termsAndConditionsUrl

      categories
      warehouseCountries
      returnPolicy
      allowShippingCostForSpecificMarkets
      flatRateShippingCountries
      shippingFlatRate
      freeShippingOnOrdersOver

      displayURL
    }
  }
`

export default GetShopSettings
