import React, { FunctionComponent } from 'react'

import Helmet from 'react-helmet'

import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'
import { StylesProvider } from '@material-ui/styles'
import { ThemeProvider } from 'styled-components'

import maTheme from './theme'

// eslint-disable-next-line react/prop-types
const StyleProvider: FunctionComponent = ({ children }) => {
  return (
    <>
      <Helmet titleTemplate="%s - MyShop" defaultTitle="MyShop" />
      <StylesProvider injectFirst>
        <MuiThemeProvider theme={maTheme}>
          <ThemeProvider theme={maTheme}>{children}</ThemeProvider>
        </MuiThemeProvider>
      </StylesProvider>
    </>
  )
}

export default StyleProvider
