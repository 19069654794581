import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'

type Props = {
  history: any
}

const ScrollToTop: React.FC<Props> = ({ history }: Props) => {
  useEffect(() => {
    const unListen = history.listen(() => {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    })
    return () => {
      unListen()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return null
}

const scroller = withRouter(ScrollToTop)

export default scroller
