import * as React from 'react'

const sleep = (m: number) => new Promise(r => setTimeout(r, m))

type componentType = React.ComponentClass<any> | null

interface InterfaceAsyncState {
  component: componentType
}

const asyncComponent = (importComponent: any) => {
  class AsyncComponent extends React.Component<any, InterfaceAsyncState> {
    constructor(props: any) {
      super(props)

      this.state = {
        component: null,
      }
    }

    async componentDidMount() {
      await sleep(150)

      const { default: component } = await importComponent()

      this.setState({
        component: component,
      })
    }

    render() {
      const C: componentType = this.state.component

      return C ? <C {...this.props} /> : <></>
    }
  }

  return AsyncComponent
}

export default asyncComponent
