import React from 'react'
import ContentLoader from 'react-content-loader'
const SideBarChildLoader = (props: any) => (
  <ContentLoader
    speed={2}
    width={100}
    height={50}
    viewBox="0 0 100 50"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <circle cx="10" cy="20" r="8" />
    <rect x="25" y="15" rx="5" ry="5" width="40" height="10" />
  </ContentLoader>
)

export default {
  SideBarChildLoader: SideBarChildLoader,
}
