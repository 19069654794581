/* eslint-disable react/prop-types */
import React, { useState } from 'react'

type ContextProps = {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const UpdateModalContext = React.createContext<ContextProps | undefined>(
  undefined,
)

export const UpdateModalProvider: React.FunctionComponent<{
  children: React.ReactNode
}> = ({ children }) => {
  const [open, setOpen] = useState<boolean>(true)

  return (
    <UpdateModalContext.Provider value={{ open, setOpen }}>
      {children}
    </UpdateModalContext.Provider>
  )
}

export const useUpdateModalState = (): ContextProps => {
  const context = React.useContext(UpdateModalContext)
  if (context === undefined) {
    throw new Error(
      'useUpdateModalState must be used within a UpdateModalProvider',
    )
  }
  return context
}
