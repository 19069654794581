import { useQuery } from '@apollo/client'
const isLoading = (dataExists: boolean) => !dataExists
export function useQueryExt(query: any, opts: {}, key = '') {
  const { data, loading, error, client, ...rest } = useQuery(query, opts)
  const dataToCheck = key ? (data ? data[key] : {}) : data
  const dataExists = Object.keys(dataToCheck || {}).length > 0
  if (loading && dataExists) client.readQuery({ query, ...opts }) // Read from cache. Error thrown if empty
  return {
    data,
    loading: isLoading(dataExists),
    error,
    ...rest,
  }
}

export default useQueryExt
