import React, { useCallback, useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { makeStyles, Button } from '@material-ui/core'
import cn from 'classnames'
import { useLocalStorage } from 'react-use'

const loader = document.createElement('div')
loader.id = 'global-loader'
const body = document.getElementsByTagName('body')
const Wrapper: React.FC<{}> = ({ children }) => {
  useEffect(() => {
    body[0].appendChild(loader)
    return () => {
      body[0].removeChild(loader)
    }
  }, [])
  return ReactDOM.createPortal(children, loader)
}

const useStyles = makeStyles({
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(27, 36, 48, 0.3)',
    zIndex: 1201,
  },
  center: {
    position: 'relative',
    top: '50%',
    left: '50%',
    height: 650,
    transform: 'translate(-50%, -50%)',

    maxWidth: 856,
    backgroundColor: 'white',
    boxShadow: '0px 0px 14px rgba(53, 64, 82, 0.05)',
    padding: 35,
    paddingTop: 55,
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'center',
    alignItems: 'center',
  },
  column: {
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '-60px',
  },
  row: {
    flexFlow: 'row nowrap',
    padding: 70,
    paddingTop: 0,
  },
  page: {
    fontFamily: 'Nunito, Helvetica, sans-serif',
    position: 'absolute',
    right: 40,
    bottom: 40,

    fontWeight: 600,
    fontSize: 24,
    lineHeight: '24px',

    textAlign: `center`,
    letterSpacing: `0.0411765`,

    color: `rgba(0, 0, 0, 0.87)`,
  },
  back: {
    fontFamily: 'Nunito, Helvetica, sans-serif',
    position: 'absolute',
    left: 40,
    bottom: 40,
    lineHeight: '24px',
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: 14,
    letterSpacing: '0.3px',
    textDecorationLine: 'underline',
    color: '#000000',
    cursor: 'pointer',
  },
  blue: {
    color: '#1E88E5',
  },
  link: {
    color: 'rgba(0, 0, 0, 0.87)!important',
    textDecoration: 'underline',
  },
  button: {
    fontFamily: 'Nunito, Helvetica, sans-serif',
    maxWidth: 302,
  },
  image: {
    margin: '30px auto',
  },
  verticalImage: {
    marginBottom: '-100px',
    height: '550px',
  },
  title: {
    fontFamily: 'Nunito, Helvetica, sans-serif',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 24,
    lineHeight: '24px',

    textAlign: 'center',
    letterSpacing: '0.0411765px',

    color: 'rgba(0, 0, 0, 0.87)',
    marginBottom: 20,
  },
  content: {
    fontFamily: 'Nunito, Helvetica, sans-serif',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 14,
    lineHeight: '21px',

    textAlign: 'center',
    letterSpacing: '0.07875px',

    color: 'rgba(0, 0, 0, 0.87)',
  },
})

const STEPS = [
  {
    title: ({ classes, publishedProducts }: any) => (
      <>
        <span className={classes?.blue}>{publishedProducts}</span> of your
        products are now live on MyShop
      </>
    ),
    description: ({ publishedProducts }: any) => (
      <>
        You have {publishedProducts} products published on MyShop.
        <br />
        To publish or un-publish products go to <strong>products</strong> page
      </>
    ),
    image: '/images/your-product-catalog.jpg',
    action: 'Next',
  },
  {
    title: () => (
      <>
        Users can now add your products
        <br />
        to their storefront
      </>
    ),
    description: ({ classes }: any) => (
      <>
        <br />
        Your published products are now live on the
        <br />
        <a
          target="_blank"
          rel="noopener noreferrer"
          className={classes?.link}
          href="https://brandbassador.com/"
        >
          Brandbassador app.
        </a>
        <br />
        <br />
        The users can add your products to their
        <br />
        own MyShop storefront and drive sales for you.
        <br />
        <br />
      </>
    ),
    action: 'Next',
    image: '/images/onboarding-phone.png',
    row: true,
  },
  {
    title: () => <>Track your sales from your Dashboard</>,
    description: () => (
      <>
        From the main dashboard you can track the sales driven through MyShop
        storefronts
      </>
    ),
    image: '/images/statistics.jpg',
    action: 'Next',
  },
  {
    title: () => <>MyShop Explained in 75 seconds</>,
    description: () => (
      <>
        <br />
        <iframe
          title="MyShop Explained in 75 seconds"
          src="https://player.vimeo.com/video/474631450"
          width="732"
          height="380"
          allow="autoplay; fullscreen"
          frameBorder="0"
        ></iframe>
        <br />
        <br />
      </>
    ),
    action: 'Let’s Go!',
  },
]

const Onboarding = ({
  name,
  comingFromSetup = false,
  publishedProducts = 0,
}: {
  name: string
  comingFromSetup: boolean
  publishedProducts: number
}) => {
  const classes = useStyles()
  const [isFirstTime, setFristTime] = useLocalStorage(
    `FIRST_TIME-${name}`,
    true,
  )
  const [step, setStep] = useState(0)
  const next = useCallback(() => {
    if (step + 1 < STEPS.length) {
      setStep(step + 1)
    } else {
      setStep(-1)
      setFristTime(false)
    }
  }, [setFristTime, step])
  const back = useCallback(() => {
    setStep(step - 1)
  }, [step])

  // useEffect(() => {
  //   if (step < 0 || !isFirstTime || !comingFromSetup) {
  //     const Intercom = (window as any).Intercom
  //     Intercom &&
  //       Intercom('boot', {
  //         // eslint-disable-next-line @typescript-eslint/camelcase
  //         app_id: 'g1bqh1bn',
  //         email,
  //         name,
  //       })
  //   }
  // }, [step, isFirstTime, comingFromSetup, email, name])

  if (step < 0 || !isFirstTime || !comingFromSetup) {
    return <></>
  }
  return (
    <Wrapper>
      <div className={classes.root}>
        {!STEPS[step]?.row && (
          <div className={classes.center}>
            <p className={classes.title}>
              {STEPS[step]?.title({
                classes,
                publishedProducts,
              })}
            </p>
            <p className={classes.content}>
              {STEPS[step]?.description({
                classes,
                publishedProducts,
              })}
            </p>
            {STEPS[step]?.image && (
              <img
                className={classes.image}
                src={STEPS[step]?.image}
                width={611}
                height={344}
                alt="small"
              />
            )}
            <Button
              onClick={next}
              variant="contained"
              color="primary"
              fullWidth
              disableElevation
              className={classes.button}
            >
              {STEPS[step]?.action}
            </Button>
            {step > 0 && (
              <p onClick={back} className={classes.back}>
                Back
              </p>
            )}
            <p className={classes.page}>{step + 1}/4</p>
          </div>
        )}

        {STEPS[step]?.row && (
          <div className={cn(classes.center, STEPS[step]?.row && classes.row)}>
            {STEPS[step]?.image && (
              <img
                className={classes.verticalImage}
                src={STEPS[step]?.image}
                alt="wide"
              />
            )}
            <div className={classes.column}>
              <p className={classes.title}>{STEPS[step]?.title({})}</p>
              <p className={classes.content}>
                {STEPS[step]?.description({ classes })}
              </p>
              <br />
              <br />
              <Button
                onClick={next}
                variant="contained"
                color="primary"
                fullWidth
                disableElevation
                className={classes.button}
              >
                {STEPS[step]?.action}
              </Button>
            </div>
            <p onClick={back} className={classes.back}>
              Back
            </p>
            <p className={classes.page}>{step + 1}/4</p>
          </div>
        )}
      </div>
    </Wrapper>
  )
}

export default Onboarding
