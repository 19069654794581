import React from 'react'
import { Users, Settings as SettingsIcon } from 'react-feather'
import { AddCircleOutline, AttachMoney } from '@material-ui/icons'

import { getShopSettings } from './shopsSettingsRoute'
import async from '../components/Async'
import dashboardIcon from '../assets/icons/dashboard.svg'
import dashboardActiveIcon from '../assets/icons/dashboard_active.svg'
import productsIcon from '../assets/icons/products.svg'
import productsActiveIcon from '../assets/icons/products_active.svg'
import communityIcon from '../assets/icons/community.svg'
import communityActiveIcon from '../assets/icons/community_active.svg'
import financeIcon from '../assets/icons/finance.svg'
import financeActiveIcon from '../assets/icons/finance_active.svg'
import settingsIcon from '../assets/icons/settings.svg'
import settingsActiveIcon from '../assets/icons/settings_active.svg'
import { getShopProducts } from './shopsProductsRoute'

// Public components
// const Home = async(() => import('../screens/Home'))

const PrivacyPolicy = async(() => import('../screens/PrivacyPolicy'))
const TermsAndConditions = async(() => import('../screens/TermsAndConditions'))
const ConnectShopify = async(() => import('../screens/ConnectShopify'))

// Auth components
const Login = async(() => import('../screens/Login'))
const Register = async(() => import('../screens/Register'))

// Private components
const ConnectShop = async(() => import('../screens/ConnectShop'))
const PendingApproval = async(() => import('../screens/PendingApproval'))

// Dashboards components
const Dashboard = async(() => import('../screens/Dashboard2'))
const EmptyPage = async(() => import('../screens/Empty'))
const PublishProducts = async(() => import('../screens/PublishProducts'))
const Settings = async(() => import('../screens/Settings'))
const BrandSettings = async(() => import('../screens/BrandSettings'))
const WithDraw = async(() => import('../screens/WithDraw'))
const MarketYourBrand = async(() => import('../screens/MarketYourBrand'))
const TermsOfService = async(() => import('../screens/TermsOfService'))
const Policies = async(() => import('../screens/Policies'))
const Setup = async(() => import('../screens/Setup'))
const ConnectWebShops = async(() => import('../screens/ConnectWebShops'))

// Integration auth components
const BigcommerceAuth = async(() =>
  import('../screens/IntegrationAuth/Bigcommerce/auth'),
)

const connectShopRoutes = {
  id: 'ConnectShop',
  path: '/connect-shop',
  // icon: <CalendarIcon />,
  component: ConnectShop,
  children: null,
  isPrivate: true,
}

const pendingApprovalRoutes = {
  id: 'PendingApproval',
  path: '/pending-approval',
  // icon: <CalendarIcon />,
  component: PendingApproval,
  children: null,
  isPrivate: true,
}

const authRoutes = {
  id: 'Auth',
  path: '',
  icon: <Users />,
  children: [
    {
      path: '/register',
      name: 'Sign In',
      component: Register,
    },
    {
      path: '/login',
      name: 'Sign Up',
      component: Login,
    },
  ],
  component: null,
}

const homeRoutes = {
  id: 'home',
  path: '',
  children: [
    {
      path: '/',
      name: 'Sign Up',
      component: Login,
    },
    {
      path: '/privacy-policy',
      name: 'Privacy policy',
      component: PrivacyPolicy,
    },
    {
      path: '/terms-and-conditions',
      name: 'Terms and Conditions',
      component: TermsAndConditions,
    },
    {
      path: '/connect-shopify',
      name: 'Connect Shopify',
      component: ConnectShopify,
    },
  ],
  component: null,
}

// dashboard routes
const dashboardsRoutes = {
  id: 'Dashboard',
  path: '/dashboard',
  icon: (
    <>
      <img className="menu-icon inactive" src={dashboardIcon} alt="dashboard" />
      <img
        className="menu-icon active"
        src={dashboardActiveIcon}
        alt="dashboard active"
      />
    </>
  ),
  isPrivate: true,
  component: Dashboard,
  children: null,
}

const publishProductsRoutes = {
  id: 'Products',
  path: '/publish-products',
  icon: (
    <>
      <img className="menu-icon inactive" src={productsIcon} alt="products" />
      <img
        className="menu-icon active"
        src={productsActiveIcon}
        alt="products active"
      />
    </>
  ),
  isPrivate: true,
  component: PublishProducts,
  hasDynamicChilds: true,
  childRoutesCb: getShopProducts,
  children: null,
}

const publishShopProductsRoutes = {
  id: 'Shop Products',
  path: '/publish-products/:settingsId',
  icon: null,
  isPrivate: true,
  component: PublishProducts,
  children: null,
}

// PS: use only in sidebar
const settingsRoutesForSidebar = {
  id: 'Settings',
  path: '/settings',
  icon: (
    <>
      <img className="menu-icon inactive" src={settingsIcon} alt="settings" />
      <img
        className="menu-icon active"
        src={settingsActiveIcon}
        alt="settings active"
      />
    </>
  ),
  isPrivate: true,
  component: Settings,
  hasDynamicChilds: true,
  childRoutesCb: getShopSettings,
  children: null,
}
const generalSettingsRoutes = {
  id: 'General Settings',
  path: '/settings',
  icon: <SettingsIcon />,
  isPrivate: true,
  component: Settings,
  children: null,
}
const brandSettingsRoutes = {
  id: 'Shop Settings',
  path: '/settings/:settingsId',
  icon: null,
  isPrivate: true,
  component: BrandSettings,
  children: null,
}

const setupRoutes = {
  id: 'Setup',
  path: '/setup/:step',
  icon: null,
  isPrivate: true,
  component: Setup,
  children: null,
}
const withdrawRoutes = {
  id: 'Finances',
  path: '/withdraw',
  icon: (
    <>
      <img className="menu-icon inactive" src={financeIcon} alt="finance" />
      <img
        className="menu-icon active"
        src={financeActiveIcon}
        alt="finance active"
      />
    </>
  ),
  isPrivate: true,
  component: WithDraw,
  children: null,
}

const marketYourBrand = {
  id: 'Community',
  path: '/market-your-brand',
  icon: (
    <>
      <img className="menu-icon inactive" src={communityIcon} alt="community" />
      <img
        className="menu-icon active"
        src={communityActiveIcon}
        alt="community active"
      />
    </>
  ),
  isPrivate: true,
  component: MarketYourBrand,
  children: null,
}

const termsOfServiceRoutes = {
  id: 'Terms Of Service',
  path: '/terms-of-service',
  icon: <AttachMoney />,
  isPrivate: true,
  component: TermsOfService,
  children: null,
}

const policiesRoutes = {
  id: 'Policies',
  path: '/policies',
  icon: <AttachMoney />,
  isPrivate: true,
  component: Policies,
  children: null,
}

const emptyRoutes = {
  id: 'Empty',
  path: '/empty',
  // icon: <CalendarIcon />,
  component: EmptyPage,
  children: null,
}

const connectWebShopsRoutes = {
  id: 'Connect Webshop',
  path: '/connect-web-shops',
  icon: <AddCircleOutline />,
  isPrivate: true,
  component: ConnectWebShops,
  children: null,
}

const bigcommerceAuthRoute = {
  id: 'bigcommerce-auth',
  path: '/integration/bigcommerce/auth',
  isPrivate: true,
  component: BigcommerceAuth,
  children: null,
}

// Routes using the Dashboard layout
export const dashboardLayoutRoutes = [
  dashboardsRoutes,
  emptyRoutes,
  publishProductsRoutes,
  publishShopProductsRoutes,
  generalSettingsRoutes,
  brandSettingsRoutes,
  withdrawRoutes,
  marketYourBrand,
  termsOfServiceRoutes,
  setupRoutes,
  policiesRoutes,
  connectWebShopsRoutes,
]

// Routes using the Public layout
export const publicLayoutRoutes = [
  homeRoutes,
  authRoutes,
  connectShopRoutes,
  pendingApprovalRoutes,
]

// Routes visible in the sidebar
export const sidebarRoutes = [
  dashboardsRoutes,
  publishProductsRoutes,
  marketYourBrand,
  withdrawRoutes,
  settingsRoutesForSidebar,
  connectWebShopsRoutes,
]

// Routes for integrations
export const integrationAuthRoutes = [bigcommerceAuthRoute]
